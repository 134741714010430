// import { useState } from "react";
import React, { useState } from "react";
import "./Booking.css";
import Modalbook from "../Booking-modal/Modalbook";
import { api } from "../../api";
import Button from "react-bootstrap/esm/Button";

function Booking() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!e) return;

    const data = new FormData();
    data.name = e.target["name"].value;
    data.email = e.target["email"].value;
    data.reservation_date = e.target["datetime"].value;
    data.number_of_guest = e.target["noOfPeople"].value;
    data.special_note = e.target["message"].value;

    fetch(`${api.ENDPOINT}/api/reservations`, {
      method: "POST",
      mode: api.MODE,
      headers: api.HEADERS,
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        setOpen(true);
        console.log("data was saved");
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <>
      {/* <!-- =========================Booking sections=============== --> */}

      <div
        className="container-xxl py-5 px-0 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="row g-0">
          <div className="col-md-6">
            <div className="video">
              <button
                className="btn-play"
                type="button"
                data-bs-toggle="modal"
                data-src=""
                data-bs-target="#videoModal"
              >
                <span></span>
              </button>
            </div>
          </div>

          <div className="col-md-6 bg-dark d-flex align-items-center">
            <div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
              <h5 className="section-title ff-secondary text-start text-success fw-normal">
                Reservations
              </h5>
              <h1 className="text-white mb-4">Book A Table Online</h1>
              {/* <!-- form --> */}

              <form id="reservation" name="reservation" onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        required
                      />
                      <label>Your Name</label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating ">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Your Email"
                        required
                      />
                      <label>Your Email</label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="form-floating date"
                      id="date3"
                      data-target-input="nearest"
                    >
                      <input
                        type="date"
                        className="form-control datetimepicker-input"
                        id="datetime"
                        name="datetime"
                        placeholder="Date &amp; Time"
                        data-target="#date3"
                        data-toggle="datetimepicker"
                        required
                      />
                      <label>Date & Time</label>
                    </div>
                  </div>

                  {/* <!-- select box --> */}
                  <div className="col-md-6">
                    <div className="form-floating">
                      <select
                        name="noOfPeople"
                        id="noOfPeople"
                        className="form-select"
                        required
                      >
                        <option value="1">People 1</option>
                        <option value="2">People 2</option>
                        <option value="3">People 3</option>
                      </select>
                      <label> No Of People</label>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        style={{ height: "100px" }}
                        id="message"
                        name="message"
                        cols="30"
                        rows="10"
                        className="form-control"
                        placeholder="Special Request"
                        required
                      ></textarea>
                      <label>Special Request</label>
                    </div>
                  </div>

                  <div className="col-12">
                    <Button type="submit" variant="success">
                      Reserve
                    </Button>
                    <Modalbook open={open} handleClose={handleClose} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Booking;
