import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import schedule from "../Schedule/Schedule";

function Modalbook({ open, handleClose }) {
  return (
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Hooray!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your Reservation is Confirmed!</Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button variant="secondary" onClick={handleClose}>
              {""}
              Back to home
            </Button>
          </Link>
          <Link to="/main/reserve/Login/schedule">
            <Button variant="success" onClick={handleClose}>
              My bookings
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Modalbook;
