import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Hero from "./components/Hero/Hero";
import Home from "./components/Home/Home";
import Booking from "./components/Booking/Booking";
import Login from "./components/Login/Loginscreen";
import Schedule from "./components/Schedule/Schedule";
import Registerscreen from "./components/Register-modal/Registerscreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "/main",
    element: <Hero />,
  },

  {
    path: "/main/reserve/",
    element: <Booking />,
  },
  {
    path: "/main/reserve/Login",
    element: <Login />,
  },
  {
    path: "/main/reserve/Login/schedule",
    element: <Schedule />,
  },
  {
    path: "/main/reserve/Login/schedule/register",
    element: <Registerscreen />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
