import React from "react";
import Cancelbook from "../Cancel-modal/Cancelbook";
import Booking from "../Booking/Booking";
import { Link } from "react-router-dom";

function schedule() {
  return (
    <section className="p-5 ">
      <div className="bg-success p-5 text-center shadow-1-strong rounded mb-5 text-white container">
        <h1>Your Booking Info</h1>
        <div className="border-top border-light w-50 mx-auto my-3">
          <div className="pt-2">
            <p>Address</p>
          </div>
        </div>
        <div>
          <div className="container pb-5">
            <div className="row ">
              <div className="col-md-6 g-3">
                <div className="card">
                  <a className="btn  " href="#" role="button">
                    <h1 className="mx-auto pt-2 ">
                      {" "}
                      <i className="fa-solid fa-calendar-days" />
                    </h1>
                  </a>
                  <div className="card-body pm-5">
                    <p className="card-text text-center text-dark">
                      March 16, 2023 <br />( Thursday )
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 g-3">
                <div className="card">
                  <a className="btn " href="#" role="button">
                    <h1 className="mx-auto pt-2 ">
                      {" "}
                      <i className="fa-solid fa-clock" />
                    </h1>
                  </a>
                  <div className="card-body pm-5">
                    <p className="card-text text-center text-dark">
                      3:00 -4:00
                      <br /> ( Pm )
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" text-center ">
            <Link to="/main/reserve/">
              <button className="btn btn-info mx-4 " type="button ">
                BACK
              </button>
            </Link>
            {/* <button class="btn btn-danger my-2" type="button" data-bs-toggle="modal"
    data-bs-target="#staticBackdrop"></button> */}

            <Cancelbook />
            {/* Modal */}
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title" id="staticBackdropLabel " />
                    {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                  </div>
                  <div className="modal-body">
                    <div className="card align-items-center py-3 card-hover rounded">
                      <br />
                      <img
                        src="./cancell.png"
                        className="img-fluid rounded-cirle"
                        width={200}
                        alt="..."
                      />
                      <div className="card-body text-center">
                        <h3 className="card-title text-dark">
                          Your booking was cancelled
                        </h3>
                      </div>
                      {/* <h3 class="text-center">Your Reservation is Confirmed! </h3> */}
                    </div>
                    <div className="modal-footer">
                      {/* <button type="button" class="btn btn-info text-light" data-bs-dismiss="modal">Close</button> */}
                      {/* <button type="button" class="btn btn-primary">View My Booking</button> */}
                      <a className="btn btn-primary" href="#" role="button">
                        HomePage
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default schedule;
