import img1 from "../assets/img/salad2.jpg";
import "./Hero.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { api } from "../../api";

function Hero() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let auth = null;
  const [login, setLogin] = useState(false);
  useEffect(() => {
    auth = localStorage.getItem(api.AUTH_KEY);
    if (!auth || auth === null || auth === "null") {
      setLogin(false);
      localStorage.setItem(api.AUTH_KEY, null);
    } else {
      setLogin(true);
    }
  }, []);

  useEffect(() => {
    auth = localStorage.getItem(api.AUTH_KEY);
    setLogin(auth);
  }, [login]);

  const navigate = useNavigate();

  const handleLoginClick = () => {
    if (!auth || auth === null || auth === "null") {
      navigate("/main/reserve/Login");
    } else {
      localStorage.setItem(api.AUTH_KEY, null);
      setLogin(false);
    }
  };

  return (
    <>
      {/* <!--==================Navbar section===============--!> */}

      <div className="container-xxl position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-4 px-lg-5 py-3 py-lg-0">
          <a href="" className="navbar-brand p-0">
            <h1 className="text-success m-0">
              <i className="fa fa-utensils me-3"></i>Vegerant
            </h1>
          </a>

          {/* <!--=============== button for responsive device================ --> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="fa fa-bars"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0 pe-4">
              {/* <!-- ==================button==================== --> */}
              <button
                className="btn btn-success btn-lg btn-block"
                onClick={handleLoginClick}
              >
                {auth === null || auth === "null" ? "Login" : "Logout"}
              </button>
              {/* <Link
                className="btn btn-success btn-lg btn-block"
                to="/main/reserve/Login"
              > */}
              {/* <Button variant="success" onClick={handleClose}>
                  {""}
                  Login
                </Button> */}{" "}
              {/* Login
              </Link> */}
              {/* <button
                type="button"
                className=" mx-3 btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#clickMeTarget6"
              >
                Register
              </button> */}
              <div className="modal fade" id="clickMeTarget6">
                <div className="modal-dialog modal-fullscreen-sm-down">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h3 className="modal-title">Login</h3>
                      <button
                        className="btn-close"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form action="" className="" />
                      <input type="text" name="" id="" placeholder="Username" />
                      <input
                        type="password"
                        name=""
                        id=""
                        placeholder="Password"
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-danger"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button className="btn btn-success">Login</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* <!-- ================hero section============= -->  */}
      <div className="container-xxl py-5 bg-dark hero-header mb-5">
        <div className="container my-5 py-5">
          <div className="row align-items-center g-5">
            <div className="col-lg-6 text center text-lg-start">
              <h1 className="display-3 text-white animated slideInLeft">
                Enjoy Our <br />
                Salad Bowl
                <br />
              </h1>
              <p className="text-white animated slideInLeft mb-4 pb-2">
                Vegetable salads may be marinated or sauced mixtures of raw or
                cooked vegetables. They are commonly based on tomatoes, green
                beans, cucumbers, beets, and mushrooms. Cole slaw ( the Dutch
                kool, “cabbage”) is made of shredded or chopped cabbage with a
                mayonnaise or vinegar-based dressing.
              </p>
              {login ? (
                <button
                  className="btn btn-success py-sm-3 px-sm-3 me-3 animated slideInLeft"
                  onClick={() => navigate("/main/reserve/")}
                >
                  {" "}
                  Book a Table
                </button>
              ) : null}
            </div>

            <div className="col-lg-6 text-center text-lg-end over-flow-hidden">
              {/* <!-- image --> */}
              <img src={img1} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
