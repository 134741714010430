import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../api";

let INITIAL_SETUP = 0;
let auth = null;

function Loginscreen() {
  const [login, setLogin] = useState(false);
  useEffect(() => {
    auth = localStorage.getItem(api.AUTH_KEY);
    if (!auth && auth === null) {
      INITIAL_SETUP = 1;
      setLogin(false);
      localStorage.setItem(api.AUTH_KEY, null);
    } else {
      INITIAL_SETUP = 1;
      setLogin(true);
    }
  }, []);

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  function Login() {
    const user = {
      email,
      password,
    };

    console.log(user);
  }

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!e) return;

    const data = {
      email: e.target["email"].value,
      password: e.target["password"].value,
    };

    fetch(`${api.ENDPOINT}/api/users/login`, {
      method: "POST",
      mode: api.MODE,
      headers: api.HEADERS,
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data.message);
        localStorage.setItem(api.AUTH_KEY, data.token);
        setLogin(data.token);
        navigate("/main");
      })
      .catch((error) => console.error(error.message));
  };

  return (
    <section className="vh-100" style={{ backgroundColor: "#009688" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: "1rem" }}>
              <div className="row g-0">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                    alt="login form"
                    className="img-fluid"
                    style={{ borderRadius: "1rem 0 0 1rem" }}
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    <form id="Login" name="Login" onSubmit={handleSubmit}>
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <i />
                        <h1 className="text-success m-0">
                          <i className="fa fa-utensils me-3"></i>Vegerant
                        </h1>
                      </div>
                      <h5
                        className="fw-normal mb-3 pb-3"
                        style={{ letterSpacing: "1px" }}
                      >
                        Sign into your account
                      </h5>
                      <div className="form-outline mb-4">
                        <input
                          type="email"
                          id="email"
                          className="form-control form-control-lg"
                          placeholder="email"
                          name="email"
                          required
                        />
                        <label
                          className="form-label"
                          htmlFor="email"
                          value={email}
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                        >
                          Email address
                        </label>
                      </div>
                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="password"
                          className="form-control form-control-lg"
                          placeholder="password"
                          name="password"
                          required
                        />
                        <label
                          className="form-label"
                          htmlFor="password"
                          value={password}
                          onChange={(e) => {
                            setpassword(e.target.value);
                          }}
                        >
                          Password
                        </label>
                      </div>
                      <div className="pt-1 mb-4">
                        <button
                          className="btn btn-success btn-lg btn-block"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                      {/* <a className="small text-muted" href="#!"> */}
                      {/* Forgot password? */}
                      {/* </a>
                      <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                        Don't have an account?{" "}
                        <Link to="/main/reserve/Login/schedule/register">
                          Register here
                        </Link>
                      </p> */}
                      <a href="#!" className="small text-muted">
                        Terms of use.
                      </a>
                      <a href="#!" className="small text-muted">
                        Privacy policy
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Loginscreen;
