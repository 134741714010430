import React from "react";
import { Link } from "react-router-dom";
import Booking from "../Booking/Booking";
import "./Home.css";
import bgImage from "../assets/img/Saladbar1.mp4";

function Home() {
  return (
    <div>
      <section className="showcase">
        <header>
          <h1 className="text-success m-0 logo">
            <i className="fa fa-utensils me-3" />
            Vegerant
          </h1>
          <div className="toggle" />
        </header>
        <video src={bgImage} muted loop autoPlay />
        <div className="overlay" />
        <div className="text">
          <h2>Eat</h2>
          <h3>Green</h3>
          <p>
            Some of the most nutritious greens include spinach, kale, romaine,
            watercress, and arugula (see "Salad greens by the numbers"). They
            are rich in a combination of vitamins A, C, and K; several B
            vitamins (including folate); and potassium. But some greens aren't
            nutrient powerhouses.
          </p>
          <Link className="btncolor" to={"main/"}>
            Eat like a King and Queen
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Home;
