const api = {
  ENDPOINT:
    process.env.NODE_ENV === "production" || process.env.USER === "ubuntu"
      ? "https://grp10api.webpark.tech"
      : "http://localhost:3002",
  HEADERS: {
    "Content-Type": "application/json",
  },
  MODE: "cors",
  AUTH_KEY: "auth",
};

export { api };
